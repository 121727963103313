<!--
 * @Author: dingguowei
 * @Date: 2023-06-06 19:30:50
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-04 18:47:46
-->

<!-- tabs任务详情页面 -->
<template>
  <div class="tabsdetails">
    <div class="head">
      <div class="deco"></div>
      <span>任务详情</span>
    </div>
    <div class="taskitem">
      <div class="itemhead">
        <div class="circle"></div>
        <span>首款：提交 {{ moneyData.downDays ? moneyData.downDays : 0 }}天后发放 {{ moneyData.downPayment ? moneyData.downPayment
          : 0
        }}元</span>
      </div>
    </div>
    <div class="taskitem">
      <div class="itemhead">
        <div class="circle"></div>
        <span>尾款：提交 {{ moneyData.finalDays ? moneyData.finalDays : 0 }}天后发放 {{ moneyData.finalPayment ?
          moneyData.finalPayment : 0
        }}元</span>
      </div>
    </div>
    <div class="taskitem" v-for="(item, index) in taskInfo" :key="index">
      <div class="itemhead">
        <div class="circle"></div>
        <span>{{ item.type === 0 ? "基本任务要求" : "附加任务要求" }} --满足奖励{{
          item.salary
        }}元</span>
      </div>
      <el-card style="font-size: 0.7rem">
        <div class="card_item" v-for="(carditem, index) in card_item" :key="index">
          <div>
            <p style="color: #5874ef; line-height: 200%">
              {{ item[carditem.fieId] ? item[carditem.fieId] : "—" }}
            </p>
            <p>{{ carditem.name }}</p>
          </div>
          <el-divider direction="vertical" v-if="carditem.showDivder"></el-divider>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    moneyData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    taskInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {
      card_item: [
        {
          name: "互动数",
          fieId: "totalNum",
          showDivder: true,
        },
        {
          name: "点赞数",
          fieId: "likeNum",
          showDivder: true,
        },
        {
          name: "收藏数",
          fieId: "collectNum",
          showDivder: true,
        },
        {
          name: "评论数",
          fieId: "commentNum",
          showDivder: true,
        },
        {
          name: "转发数",
          fieId: "shareNum",
        },
      ],
    };
  },
  created() { },
  mounted() { },
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.tabsdetails {
  .head {
    display: flex;
    align-items: center;
    font-size: 0.8rem;

    // line-height: 16px;
    .deco {
      height: 14px;
      width: 3px;
      background-color: #6080ef;
      margin-right: 10px;
    }
  }

  .taskitem {
    margin-top: 20px;

    .itemhead {
      display: flex;
      margin-bottom: 10px;
      font-size: 0.8rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      align-items: center;

      & span {
        display: block;
        margin-left: 10px;
      }

      .circle {
        position: relative;
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        background-color: #bccaf9;
      }

      .circle::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background-color: #587aef;
      }
    }

    ::v-deep .el-card__body {
      display: flex;
    }

    ::v-deep .el-divider--vertical {
      width: 2px !important;
      height: 1.2rem;
      margin: auto;
    }

    .card_item {
      padding-left: 2rem;
      align-items: center;
      display: flex;
      color: #9d9d9d;
      flex: 1;
      text-align: center;
    }
  }
}
</style>