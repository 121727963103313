<!--
 * @Author: dingguowei
 * @Date: 2023-06-09 14:23:32
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-04 19:26:13
-->
<!-- 确认信息基本情况 -->
<template>
  <el-card>
    <div class="title">
      <div class="deco"></div>
      <span>信息确认</span>
    </div>
    <el-card class="body">
      <div slot="header" class="clearfix" style="font-size: 0.8rem; font-weight: 600">
        <span>{{ taskInfo.title ? taskInfo.title : "默认标题" }}</span>
      </div>
      <div style="padding: 0 20px; overflow: hidden" v-html="taskInfo.description ? taskInfo.description : '默认内容'"
        :style="comHeight"></div>
      <p style="text-align: center; font-size: 0.7rem; color: #5874ef" @click="showMore = !showMore">
        <span v-if="showMore">收起</span>
        <span v-else>查看更多</span>
      </p>
    </el-card>

    <div class="body">
      <task_tabs_details :task-info="taskInfo.saveQoLis || taskInfo.updateQoList" :money-data="taskInfo">
      </task_tabs_details>
      <div class="newtitle">
        <div class="circle"></div>
        <span>任务信息</span>
      </div>
      <el-card style="font-size: 0.7rem">
        <el-row style="text-align: center">
          <el-col :span="12">
            <p style="line-height: 160%">
              {{ taskInfo.startAt ? taskInfo.startAt : "-" }}
            </p>
            <p style="line-height: 160%; color: #999999">任务开始时间</p>
          </el-col>
          <el-col :span="12">
            <p style="line-height: 160%">
              {{ taskInfo.endAt ? taskInfo.endAt : "-" }}
            </p>
            <p style="line-height: 160%; color: #999999">任务结束时间</p>
          </el-col>
        </el-row>
      </el-card>
      <div class="newtitle">
        <div class="circle"></div>
        <span>任务完成可提现时间</span>
      </div>
      <span style="
          display: inline-block;
          padding: 0 30px;
          border: 1px solid #e6e6e6;
          line-height: 240%;
          border-radius: 8px;
          font-size: 0.8rem;
        ">
        {{ taskInfo.cashDays }}天</span>
      <div class="newtitle">
        <div class="circle"></div>
        <span>任务奖励</span>
      </div>
      <div style="font-size: 0.7rem; line-height: 160%" v-for="(item, index) in taskInfo.saveQoList" :key="index">
        互动数满{{ item.totalNum }}奖励{{ item.salary }}
      </div>
      <div class="newtitle">
        <div class="circle"></div>
        <span>任务示例</span>
      </div>
      <el-card class="body" style="margin-left: 0">
        <div style="padding: 0 20px; overflow: hidden" v-html="taskInfo.example" :style="comegHeight"></div>
        <p style="text-align: center; font-size: 0.7rem; color: #5874ef" @click="showegMore = !showegMore">
          <span v-if="showegMore">收起</span>
          <span v-else>查看更多</span>
        </p>
      </el-card>
    </div>
  </el-card>
</template>

<script>
import task_tabs_details from "@/components/task/task_tabs_details.vue";
export default {
  props: {

    taskInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    task_tabs_details,
  },
  data() {
    return {
      showMore: false,
      showegMore: false,
    };
  },
  created() { },
  mounted() { },
  watch: {},
  computed: {
    comHeight() {
      if (!this.showMore) {
        return "height:10rem";
      } else {
        return "height:auto;min-height:10rem";
      }
    },
    comegHeight() {
      if (!this.showegMore) {
        return "height:10rem";
      } else {
        return "height:auto;min-height:10rem";
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;

  .deco {
    width: 4px;
    height: 16px;
    background-color: #6080ef;
  }

  & span {
    margin-left: 15px;
    font-size: 0.9rem;
    font-weight: bold;
  }
}

.body {
  margin: 10px 0 10px 20px;

  .newtitle {
    display: flex;
    margin: 10px 0;
    font-size: 0.8rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    align-items: center;

    & span {
      display: block;
      margin-left: 10px;
    }

    .circle {
      position: relative;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      background-color: #bccaf9;
    }

    .circle::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background-color: #587aef;
    }
  }
}
</style>